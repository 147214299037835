$primary-color: #181617;
$secondary-color: #222222;
$detail-primary-color: rgb(68, 137, 151);
$detail-primary-color-inactive: rgba(68, 137, 151, 0.4);
$text-color: #fff;

@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  background-color: $primary-color;
  height: 100vh;
}
body {
  height: 100vh;
  background-color: $primary-color;
}
#root {
  height: 100vh;
  background-color: $primary-color;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  &-title {
    font-size: 18px;
    color: $text-color;
    font-weight: bold;
  }
  &-subtitle {
    font-size: 14px;
    color: $detail-primary-color;
    font-weight: normal;
  }
  &-2 {
    color: $detail-primary-color;
  }
}

@media screen and (max-width: 768px) {
  .text {
    &-title {
      font-size: 14px;
      color: $text-color;
      font-weight: bold;
    }
    &-subtitle {
      font-size: 10px;
      color: $detail-primary-color;
      font-weight: normal;
    }
  }
}

// NAVBAR

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  // background-color: $detail-primary-color;
  backdrop-filter: blur(4px) !important;
  border: 1px solid $detail-primary-color-inactive;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 70px;
}

// SEARCHBAR

.searchbar {
  width: 100%;

  &-search {
    width: 100%;
    height: 70px;
    border-radius: 12px;
    border: none;
    outline: none;
    padding-left: 1rem;
    color: $detail-primary-color;
    &:focus {
      outline: none;
    }
  }
  ::placeholder {
    color: $detail-primary-color;
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: $detail-primary-color;
  }

  &-btn-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &-btn {
    border: 1px solid $detail-primary-color-inactive;
    cursor: pointer;
    padding: 1rem;
    height: 70px;
    width: 24%;
    text-align: center;
    border-radius: 12px;
    color: $text-color;
    @include flexCenter();
    &:hover {
      background-color: $detail-primary-color;
      transition: 0.2s linear;
    }
  }
  &-btn-active {
    background-color: $detail-primary-color;
    cursor: pointer;
    padding: 1rem;
    height: 70px;
    width: 24%;
    text-align: center;
    border-radius: 12px;
    color: $text-color;
    @include flexCenter();
  }
  &-search-btn {
    background-color: $secondary-color;
    cursor: pointer;
    padding: 1rem;
    height: 70px;
    width: 24%;
    text-align: center;
    border-radius: 12px;
    border: 1px solid $detail-primary-color-inactive;
    color: $text-color;
    @include flexCenter();
    &:hover {
      background-color: $detail-primary-color-inactive;
      transition: 0.2s linear;
    }
  }
}

.movies {
  width: 20% !important;
  height: 450px;
  &-area {
    margin: 0 auto;
    width: 100%;
  }
  &-card {
    position: relative;
    cursor: pointer;
    color: $text-color;
    border-radius: 12px;
    border: 1px solid $detail-primary-color-inactive;
    background-color: $secondary-color;
    transition: 0.5s linear;
    &:hover {
      transform: scale(1.02);
    }
    &-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 12px;
    }
    &-detail {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 175px;
      background: linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.25) 5%,
        rgba(0, 0, 0, 0.5) 10%,
        rgba(0, 0, 0, 0.8) 100%
      );
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .movies {
    width: 50% !important;
  }
}
@media screen and (max-width: 768px) {
  .movies {
    height: 350px;
  }
}

// MOVIE DETAIL
.movie-detail {
  height: calc(100% - 70px);
  &-title {
    font-size: 26px;
    color: $detail-primary-color;
    font-weight: bold;
  }
  &-subtitle {
    font-size: 20px;
    color: $detail-primary-color;
    font-weight: 100;
  }
  &-detail {
    font-size: 20px;
    color: $text-color;
    font-weight: 300;
    margin-bottom: 8px;
    text-align: center;
  }
  &-badge {
    font-size: 16px;
    color: $text-color;
    font-weight: 100;
    background-color: $detail-primary-color;
    border-radius: 12px;
    // border: 1px solid $text-color;
  }
  &-image {
    object-fit: cover;
    border: 1px solid $detail-primary-color-inactive;
    height: 80%;
    width: 60%;
    border-radius: 12px;
  }
}
// PAGINATION

.pagination {
  &-btn {
    background-color: $detail-primary-color-inactive;
    margin: 0 5px 0 5px;
    cursor: pointer;
    padding: 1rem;
    height: 40px;
    width: 40px;
    font-size: 20px;
    text-align: center;
    border-radius: 12px;
    color: $text-color;
    border: 1px solid $detail-primary-color-inactive;
    @include flexCenter();
    &:hover {
      background-color: $detail-primary-color;
      transition: 0.2s linear;
    }
  }
  &-btn-active {
    background-color: $detail-primary-color;
    margin: 0 5px 0 5px;
    cursor: pointer;
    padding: 1rem;
    height: 50px;
    width: 50px;
    font-size: 24px;
    text-align: center;
    border-radius: 12px;
    color: $text-color;
    @include flexCenter();
  }
}

// LOADER ANIMATION

@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }

  12.5% {
    left: 32px;
    top: 0;
  }

  24% {
    left: 32px;
    top: 0;
  }

  25% {
    left: 64px;
    top: 0;
  }

  35.5% {
    left: 64px;
    top: 0;
  }

  37.5% {
    left: 64px;
    top: 32px;
  }

  48% {
    left: 64px;
    top: 32px;
  }

  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }

  62.5% {
    left: 32px;
    top: 64px;
  }

  73% {
    left: 32px;
    top: 64px;
  }

  75% {
    left: 0;
    top: 64px;
  }

  85.5% {
    left: 0;
    top: 64px;
  }

  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

.loader {
  position: relative;
  width: 96px;
  height: 96px;
  transform: rotate(45deg);
  &-square {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: 8px;
    background: $detail-primary-color;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    animation: square-animation 10s ease-in-out infinite both;
  }

  &-square:nth-of-type(0) {
    animation-delay: 0s;
  }

  &-square:nth-of-type(1) {
    animation-delay: -1.4285714286s;
  }

  &-square:nth-of-type(2) {
    animation-delay: -2.8571428571s;
  }

  &-square:nth-of-type(3) {
    animation-delay: -4.2857142857s;
  }

  &-square:nth-of-type(4) {
    animation-delay: -5.7142857143s;
  }

  &-square:nth-of-type(5) {
    animation-delay: -7.1428571429s;
  }

  &-square:nth-of-type(6) {
    animation-delay: -8.5714285714s;
  }

  &-square:nth-of-type(7) {
    animation-delay: -10s;
  }
}
